<template>
	<div>
		<div class="ui-flex">
			<div class="ui-left">
				<div class="ui-left__collapsed" @click="onChangeCollapsed">
					<Icon style="color: #000" :icon="!collapsed ? 'MenuFoldOutlined' : 'MenuUnfoldOutlined'"></Icon>
				</div>
				<span id="headMenu">
					<a-menu theme="light" mode="horizontal" :selectedKeys="openKeys" :style="{ lineHeight: '50px' }">
						<template #overflowedIndicator>
							<div style="padding: 0 10px;">
								<Icon style="color: #000;" icon="EllipsisOutlined"></Icon>
							</div>
						</template>
						<template v-for="(item, index) in sideRouter" :key="index">
							<a-menu-item v-if="item.hidden !== true" :key="item.parentPath" @click="onHeaderMenuClick(index)">
								<template #icon v-if="item.meta && item.meta.icon">
								  <Icon class="ui-menu__icon" :icon="item.meta.icon"></Icon>
								</template>
								<span class="ui-menu__title">
									{{ item.meta.title }}
								</span>
							</a-menu-item>
						</template>
					</a-menu>
				</span>
			</div>
			<div class="ui-right">
				<!--   <a-dropdown :trigger="['click']">
			<div class="ui-lang">
			  <Icon icon="TranslationOutlined"></Icon>
			</div>
			<template #overlay>
			  <a-menu>
				<a-menu-item key="zh" @click="onLang('zh')"> 中文 </a-menu-item>
				<a-menu-item key="en" @click="onLang('en')"> English </a-menu-item>
			  </a-menu>
			</template>
		  </a-dropdown> -->
				<a-dropdown>
					<div class="ui-login__user">
<!--						<div class="ui-login__icon">-->
<!--							<Icon icon="UserOutlined"></Icon>-->
<!--						</div>-->
						<div class="ui-login__name">
							{{ userInfo.name }}
						</div>
					</div>
					<template #overlay>
						<a-menu>
							<a-menu-item style="padding: 10px;" @click="onChangePwd">
								<div>
									<Icon style="margin-right: 6px;" icon="EditOutlined"></Icon>
									修改密码</div>
							</a-menu-item>
							<a-menu-item style="padding: 10px;" @click="onLogout">
								<div>
									<Icon style="margin-right: 6px;" icon="LogoutOutlined"></Icon>
									退出登录</div>
							</a-menu-item>
						</a-menu>
					</template>
				</a-dropdown>
				
				<a-modal v-model:visible="showVisable" title="修改密码" @ok="onUpdatePassword">
					<a-form ref="form" name="form" :model="modelRef" :labelCol="{span: 5}" :wrapperCol="{span: 16 }">
						<a-form-item label="账号">
							{{ userInfo.name }}
						</a-form-item>
						
						<a-form-item label="旧密码" name="oldPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-password v-model:value="modelRef.oldPassword" placeholder="请输入旧密码"></a-input-password>
						</a-form-item>
						
						<a-form-item label="新密码" name="newPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-password v-model:value="modelRef.newPassword" placeholder="请输入新密码"></a-input-password>
						</a-form-item>
					</a-form>
				</a-modal>
			</div>
		</div>
		<div>
			<a-breadcrumb class="ui-bread">
				<a-breadcrumb-item v-for="(item, index) in openRoutes" :key="item.path"
					@click="onBreadClick(item, index)">
					<span
						:style="{ color: index === openRoutes.length - 1 ? '' : '' }">{{ $te(item.title) ? $t(item.title) : item.title }}</span>
				</a-breadcrumb-item>
			</a-breadcrumb>
		</div>
	</div>
</template>

<script>
	import {
		Icon
	} from "@/components/icon/icon.js";
	import {
		mapState
	} from "vuex";
	import {
		defineComponent
	} from "vue";
	import i18n from "@/language/index";
	import {
		initRouter
	} from "@/utils/routerUtil";
	import { updPassword } from '@/service/modules/system.js';

	export default defineComponent({
		props: {
			openRoutes: Array,
			selectedKeys: Array,
		},
		components: {
			Icon
		},
		computed: {
			...mapState("system", ["userInfo", "collapsed", "sideRouter"]),
		},
		watch: {
			selectedKeys: {
				handler(newVal) {
					if (newVal && newVal.length) {
						let index = 0;
						let parentPath = '';
						for(let i = 0; i < this.sideRouter.length; i++) {
							if (newVal.includes(this.sideRouter[i].parentPath)) {
								index = i;
								parentPath = this.sideRouter[i].parentPath;
								break;
							}
						}
						if (!this.openKeys.includes(parentPath)) {
							this.onHeaderMenuClick(index);
						}
					}
				},
				deep: true,
				immedate: true
			}
		},
		data() {
			return {
				openKeys: [],
				showVisable: false,
				modelRef: {
					oldPassword: '',
					newPassword: ''
				}
			}
		},
		created() {
			if (window.sessionStorage.getItem('headerOpenKey')) {
				this.openKeys = JSON.parse(window.sessionStorage.getItem('headerOpenKey'));
			} else {
				this.onHeaderMenuClick(0);
				// this.openKeys = [];
			}
		},
		methods: {
			onHeaderMenuClick(index) {
				this.openKeys = [this.sideRouter[index].parentPath];
				let children = this.sideRouter[index].children;
				this.$store.commit("system/set_leftMenu", children);
				window.sessionStorage.setItem('headerOpenKey', JSON.stringify(this.openKeys));
			},
			onChangePwd() {
				this.modelRef = {
					oldPassword: '',
					newPassword: ''
				};
				this.showVisable = true;
			},
			onUpdatePassword() {
				this.$refs.form.validateFields().then(async () => {
					let ret = await updPassword({
						oldPassword: this.modelRef.oldPassword,
						newPassword: this.modelRef.newPassword
					});
					if (ret.code === 200) {
						this.$message.success('修改成功');
						this.showVisable = false;
					}
				})
			},
			async onLogout() {
				// 清除vuex中的用户登录信息
				this.$store.dispatch("system/logout");
				// 清除缓存中的用户登录信息
				await window.sessionStorage.clear();
				// 重置路由
				initRouter();
				this.$router.replace("/login");
			},
			onLang(key) {
				i18n.global.locale = key;
				this.$store.commit("system/set_language", key);
			},
			onBreadClick(routes, index) {
				if (index === this.openRoutes.length - 1) return;
				this.$router.replace({
					path: routes.path
				});
			},
			onChangeCollapsed() {
				this.$store.commit("system/setCollapsed", !this.collapsed);
			},
		},
	});
</script>

<style lang="less" scoped>
	.ui-flex {
		display: flex;
		user-select: none;
	}

	.ui-left {
		display: flex;
		flex: 1;
	}

	.ui-left__collapsed {
		font-size: 20px;
		margin-right: 10px;
		color: #ffffff;
		cursor: pointer;
	}

	.ui-right {
		display: flex;
	}

	.ui-lang {
		margin-right: 20px;
		font-size: 22px;
		color: #ffffff;
		cursor: pointer;
	}

	.ui-bread {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-left: -20px;
		text-align: left;

		span {
			cursor: pointer;
		}
	}
	.ui-menu__title {
		margin-left: -6px;
		color: #002140;
	}
	.ui-menu__icon {
		color: #002140;
	}
	.ui-login__user {
		display: flex;
		color: #ffffff;
		align-items: center;
		cursor: pointer;
	}

	.ui-login__icon {
		width: 30px;
		height: 30px;
		line-height: 30px;
		font-size: 17px;
		background: #ccc;
		border-radius: 50%;
	}

	.ui-login__name {
		font-size: 14px;
		padding-left: 8px;
		color: #171C19;
	}
</style>

<style>
	.ui-left a {
		color: rgba(255, 255, 255, 0.9) !important;
	}

	.ui-left span {
		color: rgba(255, 255, 255, 0.6);
	}
	
	#headMenu {
		width: 80%;
	}
	#headMenu .ant-menu-item-selected .ui-menu__icon {
		color: #1890ff;
	}
	#headMenu .ant-menu-item-selected .ui-menu__title {
		color: #1890ff;
	}
</style>
